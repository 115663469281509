/**
 * MSA Portal API serving endpoints for ui
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum PopUpStatus {
    Mandatory = 'MANDATORY',
    Dismissible = 'DISMISSIBLE',
    Dismissed = 'DISMISSED',
    Inactive = 'INACTIVE'
}

