/**
 * MSA Portal API serving endpoints for ui
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum PopUpAction {
    TemporarilyDismissed = 'TEMPORARILY_DISMISSED',
    PermanentlyDismissed = 'PERMANENTLY_DISMISSED',
    ConfirmedDismissed = 'CONFIRMED_DISMISSED'
}

