/**
 * MSA Portal API serving endpoints for ui
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum PopUpType {
    UserAgreement = 'USER_AGREEMENT',
    WalletInstallation = 'WALLET_INSTALLATION',
    ContactDataVerification = 'CONTACT_DATA_VERIFICATION',
    EmergencyContactDataVerification = 'EMERGENCY_CONTACT_DATA_VERIFICATION'
}

